body {
    margin: 0;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: "Fira Code", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('./common/fonts/open-sauce-one/opensauceone-bold-webfont.woff2') format('woff2'),
    url('./common/fonts/open-sauce-one/opensauceone-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('./common/fonts/open-sauce-one/opensauceone-bolditalic-webfont.woff2') format('woff2'),
    url('./common/fonts/open-sauce-one/opensauceone-bolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('./common/fonts/open-sauce-one/opensauceone-italic-webfont.woff2') format('woff2'),
    url('./common/fonts/open-sauce-one/opensauceone-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('./common/fonts/open-sauce-one/opensauceone-regular-webfont.woff2') format('woff2'),
    url('./common/fonts/open-sauce-one/opensauceone-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('./common/fonts/open-sauce-one/opensauceone-semibold-webfont.woff2') format('woff2'),
    url('./common/fonts/open-sauce-one/opensauceone-semibold-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sauce One';
    src: url('./common/fonts/open-sauce-one/opensauceone-semibolditalic-webfont.woff2') format('woff2'),
    url('./common/fonts/open-sauce-one/opensauceone-semibolditalic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("./common/fonts/inter/Inter-Regular.woff2") format("woff2"),
    url("./common/fonts/inter/Inter-Regular.woff") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("./common/fonts/inter/Inter-Italic.woff2") format("woff2"),
    url("./common/fonts/inter/Inter-Italic.woff") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("./common/fonts/inter/Inter-Medium.woff2") format("woff2"),
    url("./common/fonts/inter/Inter-Medium.woff") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url("./common/fonts/inter/Inter-MediumItalic.woff2") format("woff2"),
    url("./common/fonts/inter/Inter-MediumItalic.woff") format("woff");
}


@font-face {
    font-family: 'Fira Code';
    src: url('./common/fonts/fira-code/woff2/FiraCode-Light.woff2') format('woff2'),
    url("./common/fonts/fira-code/woff/FiraCode-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Fira Code';
    src: url('./common/fonts/fira-code/woff2/FiraCode-Regular.woff2') format('woff2'),
    url("./common/fonts/fira-code/woff/FiraCode-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}
